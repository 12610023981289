import React from "react";

import { Link } from "gatsby";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";
import ContactFormOffer from "../../components/contactFormOffer";

const OfferPage = () => {
	const lang = "de";

	return (
		<Layout
			seoTitle="Angebot"
			lang={lang}
			translationPL="/oferta/"
			translationEN="/en/our-services/"
		>
			<SmallBanner title="Angebot" lang={lang} />
			<section className="offer-products">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
							<h2 className="color--main mb-100">Produkte</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/de/angebot/"
							>
								<img
									className="item__image"
									src={require("../../assets/img/palete.png")}
									alt="Paletten"
								/>
								<p className="bold small">Paletten</p>
								<p className="item__title big">EUR EPALL</p>
							</Link>
						</div>
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/de/angebot/"
							>
								<img
									className="item__image"
									src={require("../../assets/img/palete2.png")}
									alt="Paletten"
								/>
								<p className="bold small">Paletten</p>
								<p className="item__title big">
									CP CHEMIEPALETTEN
								</p>
							</Link>
						</div>
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/de/angebot/"
							>
								<img
									className="item__image"
									src={require("../../assets/img/palete3.png")}
									alt="Paletten"
								/>
								<p className="bold small">Paletten</p>
								<p className="item__title big">
									SONDERPALETTEN
								</p>
							</Link>
						</div>
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/de/angebot/"
							>
								<img
									className="item__image"
									src={require("../../assets/img/palete4.png")}
									alt="Paletten"
								/>
								<p className="bold small">Paletten</p>
								<p className="item__title">DÜSSELDORFER</p>
							</Link>
						</div>
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/de/angebot/palette-4-0/"
							>
								<img
									className="item__image"
									src={require("../../assets/img/palete5.png")}
									alt="Paletten"
								/>
								<p className="bold small">Palette</p>
								<p className="item__title">Palette 4.0</p>
							</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="offer-services">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
							<h2 className="color--main mb-40">
								Dienstleistungen
							</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon1.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Wärmebehandlung von Paletten (HT):
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Damit können Sie die Ware auf Ihrer
										Palette in die ganze Welt exportieren.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon2.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Trocknung von Paletten:{" "}
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Dieses Verfahren verhindert das Wachstum
										von Schimmel und Pilzen im Holz.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon3.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Markierung von Paletten durch
									Brandstempelung:
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Holen Sie sich eine Garantie für die
										Haltbarkeit der Markierungen und die
										Möglichkeit, sie an Ihre Anforderungen
										oder die Ihrer Kunden anzupassen.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon4.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Markierung von Paletten durch Färben von
									Elementen:
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Dank der Streichung können Sie Ihre
										Palette unter allen Paletten-Pools
										unterscheiden und identifizieren.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon5.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Verwendung von Metallelementen im
									Palettenbau:
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Erhöhen Sie die Festigkeit der Palette
										oder passen Sie sie an eine Kiste oder
										eine andere Konstruktion an.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon6.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Gestalten Sie Paletten nach Ihren
									individuellen Bedürfnissen:
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Passen Sie die Palette an Ihre
										Produktionslinie, Warenart und
										Transportbedingungen an.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon7.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Lagerhaltung und Haltung von Lagerbeständen:
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Erhalten Sie einen dauerhaften Zugriff
										auf Ihre Paletten, ohne Lagerplatz dafür
										zu opfern.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon8.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Maßgeschneiderte Lieferpläne, hier auch
									Just-in-Time-Lieferungen:
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Passen Sie die Liefermengen an Ihren
										aktuellen Bedarf an und optimieren Sie
										Ihren Lagerplatz.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon9.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Umreifungsband:
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Wenn Sie die Pfosten mit Umreifungsband
										binden, können Sie sicher sein, dass
										Ihre Paletten Sie in einwandfreiem
										Zustand erreichen.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon10.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Markierung von Paletten mit Aufdruck:
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Wir passen die Kennzeichnung auf der
										Palette Ihren individuellen Bedürfnissen
										an, indem wir dynamische Symbole wie
										Chargennummer oder Produktionsdatum
										aufdrucken.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon11.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Bläueschutz-Imprägnierung:
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										In kritischen Perioden für das Holz
										(Spätfrühling, Sommer) können Sie eine
										zusätzlich gegen Schimmelpilzbefall
										geschützte Palette erhalten.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon12.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Intelligente Paletten:
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Durch die Verwendung von RFID-Etiketten
										können Sie die Palette in die digitale
										Umgebung Ihres Unternehmens integrieren.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="offer-quality">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
							<h2 className="color--main mb-40">Qualität</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
							<p className="mb-100">
								PalettenWerk verfügt über ein 6-stufiges
								Qualitätskontrollsystem, das die
								Wiederholbarkeit, Haltbarkeit und langfristige
								Nutzbarkeit der Paletten garantiert. Das System
								umfasst die Kontrolle der Lieferung von
								Palettenkomponenten, die Kontrolle des
								Produktionsprozesses und der Fertigprodukte.
								Alle Kontrollprozesse sind in das
								Qualitätsmanagementsystem integriert.
							</p>
						</div>
						<div className="col-12 offer-quality__btn-holder">
							<Link
								className="btn"
								to="/de/angebot/qualitat/"
								data-hover="Mehr sehen"
							>
								Mehr sehen
								<span className="btn-arrows"></span>
							</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="offer-delivery">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
							<h2 className="color--main mb-40">Lieferung</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
							<p className="mb-100">
								PalettenWerk garantiert den Kunden schnelle und
								pünktliche Palettenlieferungen innerhalb von
								ganz Europa. Bestellungen für Standardpaletten
								in Polen werden innerhalb von 24 Stunden
								abgewickelt, Lieferungen in andere Länder
								erfolgen innerhalb von 3 Werktagen nach
								Auftragserteilung.
							</p>
						</div>
						<div className="col-12 offer-delivery__btn-holder">
							<Link
								className="btn"
								to="/de/angebot/lieferung"
								data-hover="Mehr sehen"
							>
								Mehr sehen
								<span className="btn-arrows"></span>
							</Link>
						</div>
					</div>
				</div>
			</section>
			<div className="col-lg-10 contact_formular">
				<h3>Kontaktformular</h3>
				<ContactFormOffer lang={lang} />
			</div>
		</Layout>
	);
};

export default OfferPage;
